export const strict = false
let errorTimeout = null

export const state = () => ({
  user: null,
  error: '',
  isDesktop: false,
  lastTrack: false,
  player: null,
  tracksHistory: [],
  gloading: false,
  currentTrack: null,
  playerState: 'stop',
  favs: {
    tracks: [],
    albums: [],
  },
  trackLoadingId: null,
})

export const mutations = {
  setFavs(state, payload) {
    state.favs = payload
  },
  setPlayerState(state, payload) {
    state.playerState = payload
  },
  setCurrentTrack(state, payload) {
    state.currentTrack = payload
  },
  setTracksHistory(state, payload) {
    state.tracksHistory = payload
  },
  setGLoading(state, payload) {
    state.gloading = payload
  },
  setTrackLoadingId(state, payload) {
    state.trackLoadingId = payload
  },
  setPlayer(state, payload) {
    state.player = payload
  },
  setLastTrack(state, payload) {
    state.lastTrack = payload
  },
  setDevice(state, payload) {
    state.isDesktop = payload.isDesktop
  },
  setUser(state, payload) {
    state.user = payload
  },
  setError(state, payload) {
    clearTimeout(errorTimeout)
    state.error = ''
    state.error = payload

    errorTimeout = setTimeout(() => {
      state.error = ''
    }, payload.timeout)
  },
  clearError(state) {
    state.error = ''
  },
}

export const actions = {
  nuxtServerInit({ commit }, { req, app, error }) {
    try {
      if (req.isAuthenticated()) commit('setUser', req.user)
      commit('setDevice', app.$device)
    } catch (e) {
      error('Error on [nuxtServerInit] action', e)
    }
  }
}
